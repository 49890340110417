import { Typography } from '@material-ui/core'
import Auth from '@aws-amplify/auth'
import { TFunction } from 'i18next'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { checkIfSubmittable, emailValidation } from '../../utils/validation'
import emailIcon from '../assets/images/email.svg'
import CHRButton from '../components/button'
import ContentContainer from '../components/contentContainer'
import CHRInput from '../components/input'
import CHRSectionContainer from '../components/sectionContainer'
import SEO from '../components/seo'
import CHRText from '../components/typography'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/joinsStyles'
import { setItemToLocalStorage } from '../../utils/setLocalStorage'

interface ForgotPasswordProps {
  t: TFunction
}

const ForgotPassword = ({ t }: ForgotPasswordProps) => {
  const classes = styles()
  const [codeSentText, setCodeSentText] = useState(false)
  const [isSendingCode, setIsSendingCode] = useState(false)
  const [errorMessages, setErrorMessages] = useState({
    errorText: '',
    limitErrorText: '',
  })
  const [forgotPasswordFields, setForgotPasswordFields] = useState({
    email: '',
  })

  async function handleSendCodeClick() {
    const { email } = forgotPasswordFields
    if (email.length > 0) {
      setIsSendingCode(true)
      try {
        await emailValidation({ email })
        await Auth.forgotPassword(email)

        setItemToLocalStorage('CHRemail', email)
        setCodeSentText('check your email. we sent a confirmation link there.')
        setIsSendingCode(false)
      } catch (error) {
        if (error?.message) {
          if (error?.message.includes('limit exceeded')) {
            setErrorMessages({
              limitErrorText: t('forgotPassword.limitExceeded'),
            })
            setCodeSentText('')
            setIsSendingCode(false)
            return
          }
          setErrorMessages({ errorText: error?.message })
        }
        setErrorMessages({
          errorText: t('forgotPassword.emailMustBeValidEmail'),
        })
        setIsSendingCode(false)
      }
    } else {
      setErrorMessages({ errorText: t('forgotPassword.pleaseFillEmail') })
    }
  }

  const submitHandler = event => {
    event.preventDefault()
  }

  return (
    <Layout>
      <SEO title={t('forgotPassword.metaTitle')} />
      <CHRSectionContainer>
        <ContentContainer>
          <form className={classes.container} onSubmit={submitHandler}>
            <CHRText align="left">
              {t('forgotPassword.forgotSignInDetails')}
            </CHRText>
            <div className={classes.wrapper}>
              {errorMessages.errorText && (
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.error}
                >
                  {errorMessages.errorText}
                </Typography>
              )}
              <CHRInput
                type="email"
                value={forgotPasswordFields.email}
                onChange={event => {
                  setForgotPasswordFields({
                    ...forgotPasswordFields,
                    email: event.target.value,
                  })
                  setErrorMessages({ errorText: '', limitErrorText: '' })
                  setCodeSentText('')
                }}
                placeholder={t('shared.emailAddress')}
                onKeyPress={event =>
                  !isSendingCode &&
                  checkIfSubmittable(event, handleSendCodeClick)
                }
                customStyles={classes.input}
              />
            </div>
            <div className={classes.wrapper}>
              <CHRButton
                onClick={handleSendCodeClick}
                label={t('forgotPassword.sendMeConfirmationCode')}
                icon={emailIcon}
                isSubmitting={isSendingCode}
                disabled={isSendingCode}
                customStyle={classes.buttonStyles}
              />
              {errorMessages.limitErrorText && (
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.forgotPasswordErrorMsg}
                >
                  {errorMessages.limitErrorText}
                </Typography>
              )}
              {codeSentText && (
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.successMsg}
                >
                  {codeSentText}
                </Typography>
              )}
            </div>
          </form>
        </ContentContainer>
      </CHRSectionContainer>
    </Layout>
  )
}
export default withTranslation()(ForgotPassword)
